import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import { useMutation, useQueryClient } from "react-query";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import scrollIntoView from "scroll-into-view";
import fileDownload from "js-file-download";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import ProfilSelection from "./ProfilSelection";
import ActionsContainer from "../../../react-ui/ActionsContainer";
import Delete from "../../../react-ui/Icons/Delete";
import Duplicate from "../../../react-ui/Icons/Duplicate";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import SaveButton from "../../../react-ui/SaveButton";
import DraggableBodyRow from "../../../react-ui/DraggableRow";
import DraggableTableInput from "./DraggableTableInput";
import DuplicateDisable from "../../../react-ui/Icons/DuplicateDisable";
import DeleteDisable from "../../../react-ui/Icons/DeleteDisable";
import SaveTransferButton from "../../../react-ui/SaveTransferButton";
import LogigrammeButton from "../../../react-ui/LogigrammeButton";
import { useLazyQuery } from "../../../../hooks/useLazyQuery";

const { confirm } = Modal;

const getColumns = (
  plannings,
  profils,
  removeWorkPosition,
  updateWorkPosition,
  dupWorkPositions,
  updatePlanning,
  configApplication,
  isLockedByUser
) => [
  {
    title: "Intitulé",
    dataIndex: "title",
    width: "23%",
    render: (value, record) => {
      const modified = record.hasBeenModified;
      return (
        <DraggableTableInput
          value={value}
          keyName="title"
          itemId={record.key}
          updateItem={(values) => updateWorkPosition({ ...values })}
          disabled={
            configApplication.guarding.workpositions.disable_input_title ||
            !isLockedByUser
          }
          suffix={
            record.has_calendar_changed ? (
              <i
                className="fa-duotone fa-bookmark text-danger"
                title="Calendrier modifié"
                style={{
                  marginInlineEnd: "0px",
                }}
              />
            ) : (
              <span />
            )
          }
          prefix={
            modified ? (
              <Tooltip
                placement="bottom"
                title="Les modifications ne sont pas encore enregistrées"
              >
                <ClockCircleOutlined />
              </Tooltip>
            ) : (
              <span />
            )
          }
          status={modified ? "warning" : undefined}
          isRequired
        />
      );
    },
  },
  {
    title: "Profil",
    dataIndex: "profil_id",
    width: "38%",
    render: (profil_id, record) => (
      <ProfilSelection
        options={profils}
        optionId={profil_id}
        updateItem={(values) => updateWorkPosition({ ...values })}
        itemId={record.key}
        keyName="profil_id"
        optionKeyName="designation"
        keysToSearch={["designation", "coefficient", "qualification"]}
        disabled={
          configApplication.guarding.workpositions.disable_select_profil ||
          !isLockedByUser
        }
        customTooltip
      />
    ),
  },
  {
    title: "Planning",
    dataIndex: "planning_id",
    width: "23%",
    render: (planning_id, record) => (
      <ProfilSelection
        showSearch={false}
        options={[
          {
            name: "Templates",
            options: plannings,
          },
          {
            name: "Votre planning",
            options: [
              {
                id: planning_id,
                name: record.planning_name,
              },
            ],
          },
        ]}
        optionId={planning_id}
        updateItem={(values) => updateWorkPosition({ ...values })}
        itemId={record.key}
        keyName="planning_id"
        optionKeyName="name"
        disabled={
          configApplication.guarding.workpositions.disable_select_planning ||
          !isLockedByUser
        }
        customTooltip
        confirmSelectFunction={(item, setSelectedItem) => {
          if (record.is_planning_empty) {
            updatePlanning(
              {
                id: record.id,
                planning_id: item,
              },
              { onSuccess: (payload) => setSelectedItem(payload.planning.id) }
            );
            return;
          }
          if (item === planning_id) return;
          confirm({
            title: `Le planning ${record.planning_name} sera réinitialisé`,
            icon: <ExclamationCircleOutlined />,
            okText: "Continuer",
            okType: "danger",
            maskClosable: false,
            cancelText: "Annuler",
            onOk() {
              updatePlanning(
                {
                  id: record.id,
                  planning_id: item,
                },
                { onSuccess: (payload) => setSelectedItem(payload.planning.id) }
              );
              return false;
            },
            onCancel() {
              return false;
            },
          });
        }}
      />
    ),
  },
  {
    title: "Qt",
    dataIndex: "quantity",
    width: "8%",
    render: (value, record) => (
      <DraggableTableInput
        value={value}
        keyName="quantity"
        itemId={record.key}
        isNumber
        disabled={
          configApplication.guarding.workpositions.disable_input_quantity ||
          !isLockedByUser
        }
        nbDecimal={0}
        isRequired
        textAlign="right"
        updateItem={(values) => updateWorkPosition({ ...values })}
      />
    ),
  },
  {
    title: "",
    dataIndex: "",
    width: "8%",
    render: (_, record) => (
      <ActionsContainer $colGap="8px" center>
        {(configApplication.guarding.workpositions.disable_button_duplicate ||
          !isLockedByUser) && <DuplicateDisable />}
        {!record.hasBeenModified &&
          !configApplication.guarding.workpositions.disable_button_duplicate &&
          isLockedByUser && (
            <Duplicate
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: `Êtes-vous sûr de vouloir dupliquer le poste de travail intitulé : ${record.title}`,
                  icon: <ExclamationCircleOutlined />,
                  okText: "Oui",
                  cancelText: "Non",
                  maskClosable: false,
                  onOk() {
                    dupWorkPositions(record.id);
                    return false;
                  },
                  onCancel() {
                    return false;
                  },
                });
              }}
            />
          )}
        {(configApplication.guarding.workpositions.disable_button_delete ||
          !isLockedByUser) && <DeleteDisable />}
        {!configApplication.guarding.workpositions.disable_button_delete &&
          isLockedByUser && (
            <Delete
              onClick={(e) => {
                e.stopPropagation();
                removeWorkPosition({ key: record.key });
              }}
            />
          )}
      </ActionsContainer>
    ),
  },
];

const getRowClassname = (record, selectedWorkPositionId) => {
  const classes = ["cursor-pointer"];

  if (record.className) classes.push(record.className);
  if (selectedWorkPositionId === record.key)
    classes.push(["ant-table-row-selected", "ant-table-cell-row-hover"]);
  return classes;
};

const selector = (state) => ({
  formToken: state.formToken,
});

function WorkPositionSection({
  profils,
  plannings,
  isLoadingWorkPositions,
  selectedWorkPositionId,
  setSelectedWorkPositionId,
  workPositionsLocal,
  setWorkPositionsLocal,
  worksPositionsApi = [],
  updateWorkPosition,
  opportunityId,
  isLoading,
  saveWorkPositions,
  saveWorkPositionsStatus,
  setWorksPositionsApi,
  configApplication,
  chrono,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const lastRow = useRef();
  const workPositionModified = workPositionsLocal.filter(
    (el) => el.hasBeenModified && !el.destroy
  ).length;
  const positionHaveChanged = useRef(false);
  const workPositionDelete = workPositionsLocal.filter(
    (el) => el.destroy
  ).length;

  const handleNewWorkPosition = (payload) => {
    lastRow.current = `scroll-row-${payload.id}`;
    const newWorkPos = {
      ...payload,
      key: payload.id,
      planning_name: payload.planning.name,
      className: `scroll-row-${payload.id}`,
    };
    setWorkPositionsLocal((state) => [...state, newWorkPos]);
    setWorksPositionsApi((state) => [...state, newWorkPos]);
    setSelectedWorkPositionId(payload.id);
    queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
  };

  const { mutate: dupWorkPositions, isLoading: isLoadingDup } = useMutation(
    (id) => postData(formToken, `work_positions/${id}/duplicate`, id),
    {
      onSuccess: handleNewWorkPosition,
    }
  );

  const { mutate: createWorkPositions, isLoading: isLoadingCrea } = useMutation(
    (data) => postData(formToken, `/work_position/create`, data),
    {
      onSuccess: handleNewWorkPosition,
    }
  );

  const { mutate: updatePlanning, isLoading: isLoadingPlan } = useMutation(
    (data) => postData(formToken, `/work_position/update_planning`, data),
    {
      onSuccess: (payload) => {
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === payload.id);

          newWp[index] = {
            ...newWp[index],
            is_planning_empty: payload.is_planning_empty,
            planning_id: payload.planning_id,
            planning_name: payload.planning.name,
            planning: payload.planning,
          };
          return newWp;
        });
        queryClient.invalidateQueries([
          "WorkPeriods",
          { workPositionId: payload.id },
        ]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
      },
    }
  );

  useEffect(() => {
    if (lastRow.current) {
      scrollIntoView(document.querySelector(`.${lastRow.current}`), {
        align: {
          top: 0,
        },
      });
      lastRow.current = undefined;
    }
  }, [workPositionsLocal]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = workPositionsLocal[dragIndex];
      if (dragIndex !== hoverIndex) positionHaveChanged.current = true;
      setWorkPositionsLocal(
        update(workPositionsLocal, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [workPositionsLocal, setWorkPositionsLocal]
  );

  const addWorkPosition = () => {
    const planning =
      plannings.find((plan) => plan.name === "Vierge") || plannings[0];

    createWorkPositions({
      title: "Nouveau poste",
      profil_id: profils[0].id,
      planning_id: planning.id,
      quantity: 1,
      opportunity_id: opportunityId,
    });
  };

  const removeWorkPosition = ({ key }) => {
    setSelectedWorkPositionId();
    setWorkPositionsLocal((state) => {
      const idx = state.findIndex((el) => `${el.key}` === `${key}`);
      const updatedWorkPositions = [...state];
      updatedWorkPositions[idx].destroy = true;
      return [...updatedWorkPositions];
    });
  };

  const handleOnSave = () => {
    saveWorkPositions(() => {
      positionHaveChanged.current = false;
    });
  };

  const handleStatusOnSave = () => {
    saveWorkPositionsStatus(() => {
      positionHaveChanged.current = false;
    });
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const [getXls, { isFetching }] = useLazyQuery(
    ["getXls"],
    () =>
      getData(formToken, `/opportunities/${opportunityId}/logigramme_export`, {
        responseType: "blob",
      }),
    {
      onSuccess: (res) => {
        const date = new Date();
        fileDownload(
          res,
          `Planning-ressources_${chrono}_${date
            .toLocaleDateString("fr-FR")
            .replace(/\//g, "-")}.xlsx`
        );
      },
    }
  );

  return (
    <>
      <TitleContainer
        label={`Liste des postes (${worksPositionsApi.length} poste${
          worksPositionsApi.length > 1 ? "s" : ""
        } enregistré${worksPositionsApi.length > 1 ? "s" : ""})`}
        noBorderBottom
      >
        {configApplication.guarding.workpositions.show_logigramme_button && (
          <StyledButtonExcel
            label="Planning Ressources"
            onClick={() => getXls()}
            loading={isFetching}
          />
        )}
      </TitleContainer>
      <Container>
        <InfoContainer>
          <ButtonContainer>
            <StyledSaveButton
              label="Enregistrer"
              onClick={handleOnSave}
              loading={isLoading}
              disabled={
                worksPositionsApi.length < 1 ||
                isLoading ||
                isLoadingWorkPositions ||
                isLoadingDup ||
                isLoadingCrea ||
                isLoadingPlan ||
                !configApplication.guarding.workpositions.button_save ||
                !isLockedByUser
              }
            />
            <AddButton
              label="Poste"
              onClick={addWorkPosition}
              loading={isLoadingCrea}
              disabled={
                isLoading ||
                isLoadingWorkPositions ||
                isLoadingDup ||
                isLoadingCrea ||
                isLoadingPlan ||
                !configApplication.guarding.workpositions.button_create ||
                !isLockedByUser
              }
            />
            {configApplication.guarding.workpositions
              .show_button_save_transfer && (
              <StyledSaveTransferButton
                label="Enregistrer et transmettre au fournisseur"
                onClick={handleStatusOnSave}
                loading={isLoading}
                disabled={
                  worksPositionsApi.length < 1 ||
                  isLoading ||
                  isLoadingWorkPositions ||
                  isLoadingDup ||
                  isLoadingCrea ||
                  isLoadingPlan ||
                  !configApplication.guarding.workpositions.button_save_transfer
                }
              />
            )}
          </ButtonContainer>
          <TextInfoContainer>
            {workPositionModified > 0 && (
              <PositionChangeText>
                {workPositionModified}{" "}
                {workPositionModified > 1
                  ? "éléments vont être modifiés"
                  : "élément va être modifié"}
              </PositionChangeText>
            )}
            {(positionHaveChanged.current ||
              (!positionHaveChanged.current && workPositionDelete > 0)) &&
              workPositionModified > 0 &&
              " / "}
            {positionHaveChanged.current && (
              <PositionChangeText>
                Les positions ont été modifiées
              </PositionChangeText>
            )}
            {positionHaveChanged.current && workPositionDelete > 0 && " / "}
            {workPositionDelete > 0 && (
              <DeleteWorkPositionText>
                {workPositionDelete}{" "}
                {workPositionDelete > 1
                  ? "éléments vont être supprimés"
                  : "élément va être supprimé"}
              </DeleteWorkPositionText>
            )}
          </TextInfoContainer>
        </InfoContainer>
        <DndProvider backend={HTML5Backend}>
          <StyledTable
            components={components}
            bordered
            columns={getColumns(
              plannings,
              profils,
              removeWorkPosition,
              updateWorkPosition,
              dupWorkPositions,
              updatePlanning,
              configApplication,
              isLockedByUser
            )}
            dataSource={workPositionsLocal.filter((el) => !el.destroy)}
            scrollY
            pagination={false}
            rowClassName={(record) =>
              getRowClassname(record, selectedWorkPositionId)
            }
            loading={isLoadingWorkPositions}
            onRow={(record, index) => {
              const attr = {
                index,
                moveRow,
                itemId: record.key,
                onClick: () => setSelectedWorkPositionId(record.id),
              };
              return attr;
            }}
          />
        </DndProvider>
      </Container>
    </>
  );
}

const StyledTable = styled(TableContainer)`
  .ant-table-row-selected > .ant-table-cell {
    background: #bae0ff !important;
  }
`;

const StyledSaveButton = styled(SaveButton)`
  margin-right: 8px;
`;

const StyledSaveTransferButton = styled(SaveTransferButton)`
  margin-left: 8px;
`;

const PositionChangeText = styled.span`
  color: #faad14;
`;

const DeleteWorkPositionText = styled.span`
  color: red;
`;

const InfoContainer = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  padding-left: 4px;
  padding-right: 4px;
`;

const TextInfoContainer = styled.div`
  margin-left: 8px;
`;

const StyledButtonExcel = styled(LogigrammeButton)`
  margin-right: 4px;
  max-height: 45px;
`;

export default WorkPositionSection;
