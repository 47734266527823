import { Divider } from "antd";
import React from "react";
import ArrayBlockVariable from "./ArrayBlockVariable";
import TextBlockVariable from "./TextBlockVariable";

export default function VariableSection({
  textBlock,
  setTextBlock,
  arrayBlock,
  setArrayBlock,
  products,
  setConfiguration,
  configuration,
  general,
}) {
  const apiOpportunityLabels = {
    pricesWork: "PRICES_WORK",
    detailedPricesWork: "DETAILED_PRICES_WORK",
    detailedPricesSubtotalPricesOnlyWork:
      "DETAILED_PRICES_SUBTOTAL_PRICES_ONLY_WORK",
    detailedPricesOnlyTotalPriceWork: "DETAILED_PRICES_ONLY_TOTAL_PRICE_WORK",
    detailedPricesOnlyTotalAndZonePriceWork:
      "DETAILED_PRICES_ONLY_TOTAL_AND_ZONE_PRICE_WORK",
    pricesOptionWork: "PRICES_OPTION_WORK",
    pricesOptionVatWork: "PRICES_OPTION_VAT_WORK",
    detailedPricesOptionWork: "DETAILED_PRICES_OPTION_WORK",
    detailedPricesOptionSubtotalPricesOnlyWork:
      "DETAILED_PRICES_OPTION_SUBTOTAL_PRICES_ONLY_WORK",
    detailedPricesOptionOnlyTotalPriceWork:
      "DETAILED_PRICES_OPTION_ONLY_TOTAL_PRICE_WORK",
    detailedPricesOptionOnlyTotalAndZonePriceWork:
      "DETAILED_PRICES_OPTION_ONLY_TOTAL_AND_ZONE_PRICE_WORK",
    materialsOptionWork: "MATERIALS_OPTION_WORK",
    materialsOptionZonesWork: "MATERIALS_OPTION_ZONES_WORK",
    materialsWork: "MATERIALS_WORK",
    materialsZonesWork: "MATERIALS_ZONES_WORK",
    fonctionnalitiesWork: "FONCTIONNALITIES_WORK",
    fonctionnalitiesZonesWork: "FONCTIONNALITIES_ZONES_WORK",
    fonctionnalitiesOptionWork: "FONCTIONNALITIES_OPTION_WORK",
    fonctionnalitiesOptionZonesWork: "FONCTIONNALITIES_OPTION_ZONES_WORK",
    prestationsWork: "PRESTATIONS_WORK",
    prestationsZonesWork: "PRESTATIONS_ZONES_WORK",
    prestationDescriptionWork: "PRESTATIONS_DESCRIPTION_WORK",
    prestationPictureWork: "PRESTATIONS_PICTURE_WORK",
    prestationsOptionWork: "PRESTATIONS_OPTION_WORK",
    prestationsOptionZonesWork: "PRESTATIONS_OPTION_ZONES_WORK",
    prestationOptionDescriptionWork: "PRESTATIONS_OPTION_DESCRIPTION_WORK",
    prestationOptionPictureWork: "PRESTATIONS_OPTION_PICTURE_WORK",
    ouvragesWork: "OUVRAGES_WORK",
    ouvragesDetailsWork: "OUVRAGES_DETAILS_WORK",
    ouvragesOptionWork: "OUVRAGES_OPTION_WORK",
    ouvragesOptionDetailsWork: "OUVRAGES_OPTION_DETAILS_WORK",
    allArticlesWithoutPricesWork: "ALL_ARTICLES_WITHOUT_PRICES_WORK",
    allArticlesWithoutPricesZonesWork: "ALL_ARTICLES_WITHOUT_PRICES_ZONES_WORK",
    allArticlesOptionWithoutPricesWork:
      "ALL_ARTICLES_OPTION_WITHOUT_PRICES_WORK",
    allArticlesOptionWithoutPricesZonesWork:
      "ALL_ARTICLES_OPTION_WITHOUT_PRICES_ZONES_WORK",
    clientContactsWork: "CLIENT_CONTACTS_WORK",

    providedCustomerContactsWork: "PROVIDED_CUSTOMER_CONTACTS_WORK",
    decisionMakerCustomerContactsWork: "DECISION_MAKER_CUSTOMER_CONTACTS_WORK",
    contactsWork: "CONTACTS_WORK",
    referencesGlobal: "REFERENCES_GLOBAL",
    referencesGlobalTypology: "REFERENCES_GLOBAL_TYPOLOGY",
    headOfficeTurnoverWork: "HEAD_OFFICE_TURNOVER_WORK",
    headOfficeTurnoverRepartitionWork: "HEAD_OFFICE_TURNOVER_REPARTITION_WORK",
    referencesGlobalHeadOffice: "REFERENCES_GLOBAL_HEAD_OFFICE",
    referencesTypologyHeadOffice: "REFERENCES_TYPOLOGY_HEAD_OFFICE",
    entityTurnoverWork: "ENTITY_TURNOVER_WORK",
    entityTurnoverRepartitionWork: "ENTITY_TURNOVER_REPARTITION_WORK",
    referencesGlobalEntity: "REFERENCES_GLOBAL_ENTITY",
    referencesTypologyEntity: "REFERENCES_TYPOLOGY_ENTITY",
  };
  const handleSaveNewValue = (value, name, type) => {
    if (type === "textBlock") {
      setTextBlock({
        ...textBlock,
        [name]: value === false || value === undefined ? undefined : null,
      });
    } else {
      setArrayBlock({ ...arrayBlock, [name]: value });

      const itemInArray = apiOpportunityLabels[name];
      const opportunityArray = configuration.api.opportunity;
      const indexByLabel = opportunityArray.findIndex(
        (item) => item === itemInArray
      );

      if (value) {
        // Ajout si non existant
        if (indexByLabel === -1) {
          setConfiguration({
            ...configuration,
            api: {
              ...configuration.api,
              opportunity: [...opportunityArray, itemInArray],
            },
          });
        }
      } else {
        // Suppression si existant
        if (indexByLabel !== -1) {
          const newOpportunity = opportunityArray.filter(
            (item) => item !== itemInArray
          );
          setConfiguration({
            ...configuration,
            api: {
              ...configuration.api,
              opportunity: newOpportunity,
            },
          });
        }
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "24px",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <TextBlockVariable
        handleSaveNewValue={handleSaveNewValue}
        textBlock={textBlock}
        setTextBlock={setTextBlock}
        configuration={configuration}
        setConfiguration={setConfiguration}
        general={general}
      />
      <Divider
        type="vertical"
        style={{
          borderColor: "rgba(11, 34, 57, 1)",
          height: "auto",
          margin: "10px 0",
        }}
      />
      <ArrayBlockVariable
        general={general}
        products={products}
        handleSaveNewValue={handleSaveNewValue}
        arrayBlock={arrayBlock}
        setArrayBlock={setArrayBlock}
        configuration={configuration}
        setConfiguration={setConfiguration}
        apiOpportunityLabels={apiOpportunityLabels}
      />
    </div>
  );
}
