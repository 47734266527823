import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { deepEqual } from "../FilesImport";
import AdministrationSection from "../LeftSideGeneralComponent/AdministrationSection";
import GeneralSection from "../LeftSideGeneralComponent/GeneralSection";
import VariableSection from "../LeftSideGeneralComponent/Variables/VariableSection";
import {
  checkIsItemInOpportunityVariable,
  updateStateGeneral,
} from "./helper-general";

export default function GeneralComponent({
  props,
  configuration,
  setConfiguration,
}) {
  const [file, setFile] = useState(configuration);

  const [general, setGeneral] = useState({
    pageSelectionItems: file?.costing.page_1?.visible,
    accordCadre: file?.general?.framework_agreement,
    maintenance: file?.general?.maintenance,
    deplacements: file?.admin?.movement_tab?.visible,
    makers: file?.admin?.maker_tab?.visible,
    carrycotsMovement: file?.costing?.page_2?.visible,
    offreLocative: file?.general?.renting,
    regroupement: file?.admin?.grouping_tab?.visible,
    carrycots: file?.admin?.carrycot_tab?.visible,
    distributors: file?.admin?.distributor_tab?.visible,
    prixVente: file?.costing?.page_3?.visible,
    ouvrage: file?.ouvrage,
    batiprix: file?.external_api?.batiprix,
    profils: file?.admin?.profil_tab?.visible,
    groupement: file?.group,
    option: file?.option,
  });

  const [products, setProducts] = useState({
    marge: file?.admin?.product_tab?.form?.input_margin_rate,
    colonneMarge: file?.admin?.product_tab?.table_marge_coeff,
    dateValidite: file?.admin?.product_tab?.form?.input_date_validity,
    associerProduits:
      file?.admin?.product_tab?.form?.product_associate?.input_linked,
  });

  const [prestations, setPrestations] = useState({
    margePrestation: file?.admin?.prestation_tab?.form?.input_margin_rate,
    description: file?.admin?.prestation_tab?.form?.input_description,
    speed: file?.admin?.prestation_tab?.form?.input_speed,
    majoration: file?.admin?.prestation_tab?.form?.input_majoration,
  });

  const [profils, setProfils] = useState({
    margeProfil: file?.admin?.profil_tab?.form?.input_margin_rate ?? false,
  });

  const [categories, setCategories] = useState({
    margeCategorie: file?.admin?.categories_tab?.form?.input_margin_rate,
  });

  const [clients, setClients] = useState({
    activiteRequise: file?.clients?.required_activity,
  });

  const [textBlock, setTextBlock] = useState({
    opportunityName: file?.api?.opportunity_variable?.NAME,
    offerName: file?.api?.opportunity_variable?.OFFER_NAME,
    opportunityValidityDate: file?.api?.opportunity_variable?.VALIDITY_DATE,
    opportunityCreatedDate:
      file?.api?.opportunity_variable?.OPPORTUNITY_CREATED_DATE,
    chono: file?.api?.opportunity_variable?.CHRONO,
    opportunityStartDate: checkIsItemInOpportunityVariable(
      "opportunity_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.QUOTATION_START_DATE
      : undefined,
    opportunityEndDate: checkIsItemInOpportunityVariable(
      "opportunity_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.QUOTATION_END_DATE
      : undefined,
    opportunitySubmissionDate: checkIsItemInOpportunityVariable(
      "opportunity_limit_date",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.SUBMISSION_DATE
      : undefined,
    opportunityType: file?.api?.opportunity_variable?.OPPORTUNITY_TYPE,
    responseType: checkIsItemInOpportunityVariable(
      "opportunity_response_type",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.RESPONSE_TYPE
      : undefined,
    customerDecisionDate: checkIsItemInOpportunityVariable(
      "decision_date",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.CUSTOMER_DECISION_DATE
      : undefined,
    description: checkIsItemInOpportunityVariable(
      "opportunity_description",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DESCRIPTION
      : undefined,
    estimatedAmount: checkIsItemInOpportunityVariable(
      "estimate_price",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.ESTIMATED_AMOUNT
      : undefined,
    siteTypologies: checkIsItemInOpportunityVariable(
      "type_of_site",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.SITE_TYPOLOGIES
      : undefined,
    sellingPrice: general.prixVente
      ? file?.api?.opportunity_variable?.SELLING_PRICE
      : undefined,
    sellingPriceTTC: file?.api?.opportunity_variable?.SELLING_PRICE_VAT,
    successProbability: checkIsItemInOpportunityVariable(
      "probability_success",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.SUCCESS_PROBABILITY
      : undefined,
    cancellationProbability: checkIsItemInOpportunityVariable(
      "probability_cancelation",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.CANCELLATION_PROBABILITY
      : undefined,
    providers: checkIsItemInOpportunityVariable(
      "provider_in_place",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDERS
      : undefined,
    general_client_contacts_name: checkIsItemInOpportunityVariable(
      "company_contact",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.COMPANY_CONTACT_NAME
      : undefined,
    general_client_contacts_phone: checkIsItemInOpportunityVariable(
      "company_contact",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.COMPANY_CONTACT_PHONE
      : undefined,
    general_client_contacts_address: checkIsItemInOpportunityVariable(
      "company_contact",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.COMPANY_CONTACT_ADDRESS
      : undefined,
    general_client_contacts_mail: checkIsItemInOpportunityVariable(
      "company_contact",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.COMPANY_CONTACT_MAIL
      : undefined,

    provided_client_contacts_name: checkIsItemInOpportunityVariable(
      "provided_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDED_CONTACT_NAME
      : undefined,
    provided_client_contacts_phone: checkIsItemInOpportunityVariable(
      "provided_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDED_CONTACT_PHONE
      : undefined,
    provided_client_contacts_address: checkIsItemInOpportunityVariable(
      "provided_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDED_CONTACT_ADDRESS
      : undefined,
    provided_client_contacts_mail: checkIsItemInOpportunityVariable(
      "provided_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.PROVIDED_CONTACT_MAIL
      : undefined,

    decision_client_contacts_name: checkIsItemInOpportunityVariable(
      "decision_maker_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DECISION_CONTACT_NAME
      : undefined,
    decision_client_contacts_phone: checkIsItemInOpportunityVariable(
      "decision_maker_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DECISION_CONTACT_PHONE
      : undefined,
    decision_client_contacts_address: checkIsItemInOpportunityVariable(
      "decision_maker_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DECISION_CONTACT_ADDRESS
      : undefined,
    decision_client_contacts_mail: checkIsItemInOpportunityVariable(
      "decision_maker_customer_contacts",
      "complement",
      file
    )
      ? file?.api?.opportunity_variable?.DECISION_CONTACT_MAIL
      : undefined,

    billed_client_contacts_name: checkIsItemInOpportunityVariable(
      "client_contacts",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.CLIENT_CONTACT_NAME
      : undefined,
    billed_client_contacts_phone: checkIsItemInOpportunityVariable(
      "client_contacts",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.CLIENT_CONTACT_PHONE
      : undefined,
    billed_client_contacts_address: checkIsItemInOpportunityVariable(
      "client_contacts",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.CLIENT_CONTACT_ADDRESS
      : undefined,
    billed_client_contacts_mail: checkIsItemInOpportunityVariable(
      "client_contacts",
      "mandatory",
      file
    )
      ? file?.api?.opportunity_variable?.CLIENT_CONTACT_MAIL
      : undefined,
    frameworkAgreement: file?.api?.opportunity_variable?.FRAMEWORK_AGREEMENT,
  });

  const [arrayBlock, setArrayBlock] = useState({});

  function updateConfigurationBySection(section, data) {
    if (section === "general") {
      const generalInfo = {
        ...configuration.general,
        framework_agreement: data.accordCadre,
        maintenance: data.maintenance,
        renting: data.offreLocative,
      };

      let adminInfo = {
        ...configuration.admin,
        movement_tab: {
          ...configuration.admin.movement_tab,
          visible: data.deplacements,
        },
        maker_tab: {
          ...configuration.admin.maker_tab,
          visible: data.makers,
        },
        grouping_tab: {
          ...configuration.admin.grouping_tab,
          visible: data.regroupement,
        },
        carrycot_tab: {
          ...configuration.admin.carrycot_tab,
          visible: data.profils ? data.carrycots : false,
        },
        distributor_tab: {
          ...configuration.admin.distributor_tab,
          visible: data.distributors,
        },
        product_tab: {
          ...configuration.admin.product_tab,
          csv: {
            ...configuration.admin.product_tab.csv,
            import_field_manufacturer_id: data.makers,
            import_field_distributor_ids: data.distributors,
          },
          form: {
            ...configuration.admin.product_tab.form,
            input_manufacturer_id: data.makers,
            input_reference_constructor: data.makers,
            input_distributor_id: data.distributors,
          },
          prestation_associate_table: {
            ...configuration.admin.product_tab.prestation_associate_table,
            speed_quantity: prestations.speed,
          },
        },
        profil_tab: {
          ...configuration.admin.profil_tab,
          visible: data.profils,
        },
        ouvrage_tab: {
          ...configuration.admin.ouvrage_tab,
          visible: data.ouvrage,
        },
        group_tab: {
          ...configuration.admin.group_tab,
          visible: data.groupement,
        },
      };

      let costingInfo = {
        ...configuration.costing,
        page_1: {
          ...configuration.costing.page_1,
          visible: data.pageSelectionItems,
        },
        page_2: {
          ...configuration.costing.page_2,
          visible: data.carrycotsMovement,
        },
        page_3: {
          ...configuration.costing.page_3,
          visible: data.prixVente,
        },
      };

      let apiInfo = {
        ...configuration.api,
      };

      if (!data.pageSelectionItems) {
        const removeItemsOpportunity = [
          "MATERIALS_WORK",
          "MATERIALS_ZONES_WORK",
          "FONCTIONNALITIES_WORK",
          "FONCTIONNALITIES_ZONES_WORK",
          "PRESTATIONS_WORK",
          "PRESTATIONS_ZONES_WORK",
          "PRESTATION_DESCRIPTION_WORK",
          "PRESTATIONS_PICTURE_WORK",
          "OUVRAGES_WORK",
          "OUVRAGES_DETAILS_WORK",
          "MATERIALS_OPTION_WORK",
          "MATERIALS_OPTION_ZONES_WORK",
          "FONCTIONNALITIES_OPTION_WORK",
          "FONCTIONNALITIES_OPTION_ZONES_WORK",
          "PRESTATIONS_OPTION_WORK",
          "PRESTATIONS_OPTION_ZONES_WORK",
          "PRESTATIONS_OPTION_DESCRIPTION_WORK",
          "PRESTATIONS_OPTION_PICTURE_WORK",
          "OUVRAGES_OPTION_WORK",
          "OUVRAGES_OPTION_DETAILS_WORK",
        ];
        const opportunityArray = Object.values(configuration.api.opportunity);

        const newOpportunityArray = opportunityArray.filter(
          (item) => !removeItemsOpportunity.includes(item)
        );
        apiInfo = {
          ...configuration.api,
          opportunity: newOpportunityArray,
        };

        adminInfo = {
          ...adminInfo,
          product_tab: {
            ...adminInfo.product_tab,
            visible: false,
            form: {
              ...adminInfo.product_tab.form,
              input_margin_rate: false,
              input_date_validity: false,
              product_associate: {
                ...adminInfo.product_tab.form.product_associate,
                input_linked: false,
              },
            },
            table_marge_coeff: false,
          },
          prestation_tab: {
            ...adminInfo.prestation_tab,
            visible: false,
            form: {
              ...adminInfo.prestation_tab.form,
              input_margin_rate: false,
              input_description: false,
              input_speed: false,
              input_majoration: false,
            },
          },
          profil_tab: {
            ...adminInfo.profil_tab,
            form: {
              ...adminInfo.profil_tab.form,
              input_margin_rate: profils.margeProfil,
            },
          },
          categories_tab: {
            ...adminInfo.categories_tab,
            form: {
              ...adminInfo.categories_tab.form,
              input_margin_rate: categories.margeCategorie,
            },
          },
        };
      }

      if (
        !configuration.costing.page_1.visible &&
        !configuration.costing.page_2.visible
      ) {
        costingInfo = {
          ...costingInfo,
          page_3: {
            ...costingInfo.page_3,
            visible: false,
          },
        };
      }

      if (!data.maintenance) {
        apiInfo = {
          ...apiInfo,
          opportunity_variable: {
            ...configuration.api.opportunity_variable,
          },
        };
        delete apiInfo.opportunity_variable.OPPORTUNITY_TYPE;
        setConfiguration({
          ...configuration,
          general: generalInfo,
          api: apiInfo,
          admin: adminInfo,
          costing: costingInfo,
          ouvrage: data.ouvrage,
          external_api: {
            ...configuration.external_api,
            batiprix: data.batiprix,
          },
          group: data.groupement,
          option: data.option,
        });
      }

      if (
        !data.prixVente ||
        (!data.pageSelectionItems && !data.carrycotsMovement)
      ) {
        adminInfo = {
          ...adminInfo,
          product_tab: {
            ...adminInfo.product_tab,
            form: {
              ...adminInfo.product_tab.form,
              input_margin_rate: products.marge,
            },
            table_marge_coeff: products.colonneMarge,
          },
          prestation_tab: {
            ...adminInfo.prestation_tab,
            form: {
              ...adminInfo.prestation_tab.form,
              input_margin_rate: prestations.margePrestation,
            },
          },
          profil_tab: {
            ...adminInfo.profil_tab,
            form: {
              ...adminInfo.profil_tab.form,
              input_margin_rate: profils.margeProfil,
            },
          },
          categories_tab: {
            ...adminInfo.categories_tab,
            form: {
              ...adminInfo.categories_tab.form,
              input_margin_rate: categories.margeCategorie,
            },
          },
        };

        const removeItemsOpportunity = [
          "PRICES_WORK",
          "DETAILED_PRICES_WORK",
          "DETAILED_PRICES_SUBTOTAL_PRICES_ONLY_WORK",
          "DETAILED_PRICES_ONLY_TOTAL_PRICE_WORK",
          "DETAILED_PRICES_ONLY_TOTAL_AND_ZONE_PRICE_WORK",
          "PRICES_OPTION_WORK",
          "PRICES_OPTION_VAT_WORK",
          "DETAILED_PRICES_OPTION_WORK",
          "DETAILED_PRICES_OPTION_SUBTOTAL_PRICES_ONLY_WORK",
          "DETAILED_PRICES_OPTION_ONLY_TOTAL_PRICE_WORK",
          "DETAILED_PRICES_OPTION_ONLY_TOTAL_AND_ZONE_PRICE_WORK",
        ];
        const opportunityArray = Object.values(configuration.api.opportunity);

        const newOpportunityArray = opportunityArray.filter(
          (item) => !removeItemsOpportunity.includes(item)
        );
        apiInfo = {
          ...apiInfo,
          opportunity: newOpportunityArray,
          opportunity_variable: {
            ...configuration.api.opportunity_variable,
          },
        };
        delete apiInfo.opportunity_variable.SELLING_PRICE;
        delete apiInfo.opportunity_variable.SELLING_PRICE_VAT;
        setConfiguration({
          ...configuration,
          general: generalInfo,
          api: apiInfo,
          admin: adminInfo,
          costing: costingInfo,
          ouvrage: data.ouvrage,
          external_api: {
            ...configuration.external_api,
            batiprix: data.batiprix,
          },
          group: data.groupement,
          option: data.option,
        });
      }
      if (!data.option) {
        const removeItemsOpportunity = [
          "PRICES_OPTION_WORK",
          "PRICES_OPTION_VAT_WORK",
          "DETAILED_PRICES_OPTION_WORK",
          "DETAILED_PRICES_OPTION_SUBTOTAL_PRICES_ONLY_WORK",
          "DETAILED_PRICES_OPTION_ONLY_TOTAL_PRICE_WORK",
          "MATERIALS_OPTION_WORK",
          "MATERIALS_OPTION_ZONES_WORK",
          "FONCTIONNALITIES_OPTION_WORK",
          "FONCTIONNALITIES_OPTION_ZONES_WORK",
          "PRESTATIONS_OPTION_WORK",
          "PRESTATIONS_OPTION_ZONES_WORK",
          "PRESTATIONS_OPTION_DESCRIPTION_WORK",
          "PRESTATIONS_OPTION_PICTURE_WORK",
          "OUVRAGES_OPTION_WORK",
          "OUVRAGES_OPTION_DETAILS_WORK",
          "ALL_ARTICLES_OPTION_WITHOUT_PRICES_WORK",
          "ALL_ARTICLES_OPTION_WITHOUT_PRICES_ZONES_WORK",
        ];
        const opportunityArray = Object.values(configuration.api.opportunity);
        const newOpportunityArray = opportunityArray.filter(
          (item) => !removeItemsOpportunity.includes(item)
        );
        apiInfo = {
          ...apiInfo,
          opportunity: newOpportunityArray,
          opportunity_variable: {
            ...configuration.api.opportunity_variable,
          },
        };
      }

      if (!data.accordCadre) {
        adminInfo = {
          ...adminInfo,
          prestation_tab: {
            ...adminInfo.prestation_tab,
            form: {
              ...adminInfo.prestation_tab.form,
              input_majoration: false,
            },
          },
        };

        apiInfo = {
          ...apiInfo,
          opportunity_variable: {
            ...configuration.api.opportunity_variable,
          },
        };
        delete apiInfo.opportunity_variable.FRAMEWORK_AGREEMENT;
      }

      if (!data.deplacements) {
        adminInfo = {
          ...adminInfo,
          movement_tab: {
            ...adminInfo.movement_tab,
            visible: false,
          },
        };
        costingInfo = {
          ...costingInfo,
          page_2: {
            ...costingInfo.page_2,
            show_movements: false,
          },
        };
      }

      if (!data.carrycots) {
        adminInfo = {
          ...adminInfo,
          carrycot_tab: {
            ...adminInfo.carrycot_tab,
            visible: false,
          },
        };
        costingInfo = {
          ...costingInfo,
          page_2: {
            ...costingInfo.page_2,
            show_carrycots: false,
          },
        };
      }

      // console.log("data", data, profils.margeProfil);
      if (!data.profils) {
        adminInfo = {
          ...adminInfo,
          profil_tab: {
            ...adminInfo.profil_tab,
            form: {
              ...adminInfo.profil_tab.form,
              input_margin_rate: false,
            },
            visible: false,
          },
        };
      }

      if (
        !deepEqual(configuration.general, generalInfo) ||
        JSON.stringify(configuration.api.opportunity) !==
          JSON.stringify(apiInfo.opportunity) ||
        JSON.stringify(configuration.api.opportunity_variable) !==
          JSON.stringify(apiInfo.opportunity_variable) ||
        !deepEqual(configuration.admin, adminInfo) ||
        !deepEqual(configuration.costing, costingInfo) ||
        !deepEqual(configuration.external_api.batiprix, data.batiprix) ||
        configuration.ouvrage !== data.ouvrage ||
        configuration.group !== data.groupement ||
        configuration.option !== data.option
      ) {
        setConfiguration({
          ...configuration,
          general: generalInfo,
          api: apiInfo,
          admin: adminInfo,
          costing: costingInfo,
          ouvrage: data.ouvrage,
          external_api: {
            ...configuration.external_api,
            batiprix: data.batiprix,
          },
          group: data.groupement,
          option: data.option,
        });
      }
    }
  }

  useEffect(() => {
    updateConfigurationBySection("general", general);
  }, [general]);

  useEffect(() => {
    if (!deepEqual(file, configuration)) {
      setFile(configuration);
    }
  }, [configuration]);

  useEffect(() => {
    updateStateGeneral(
      file,
      setClients,
      setGeneral,
      setProducts,
      setPrestations,
      setProfils,
      setTextBlock,
      setCategories,
      props,
      checkIsItemInOpportunityVariable,
      general
    );
  }, [file]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <StyledDivider
        style={{
          height: "24px",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      >
        Général
      </StyledDivider>
      <GeneralSection
        props={props}
        file={file}
        setConfiguration={setConfiguration}
        configuration={configuration}
        userRole={props.role}
        general={general}
        setGeneral={setGeneral}
        setArrayBlock={setArrayBlock}
        setTextBlock={setTextBlock}
        setProducts={setProducts}
        setPrestations={setPrestations}
        setProfils={setProfils}
        setCategories={setCategories}
      />
      <StyledDivider
        style={{
          height: "24px",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      >
        Administration
      </StyledDivider>
      <AdministrationSection
        props={props}
        file={file}
        userRole={props.role}
        general={general}
        products={products}
        prestations={prestations}
        profils={profils}
        categories={categories}
        clients={clients}
        setProducts={setProducts}
        setPrestations={setPrestations}
        setProfils={setProfils}
        setCategories={setCategories}
        setClients={setClients}
        setConfiguration={setConfiguration}
        configuration={configuration}
      />

      <StyledDivider
        style={{
          height: "24px",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      >
        Variables
      </StyledDivider>
      <VariableSection
        props={props}
        file={file}
        userRole={props.role}
        textBlock={textBlock}
        arrayBlock={arrayBlock}
        setTextBlock={setTextBlock}
        setArrayBlock={setArrayBlock}
        products={products}
        configuration={configuration}
        setConfiguration={setConfiguration}
        general={general}
      />
    </div>
  );
}

const StyledDivider = styled(Divider)`
  .ant-divider-inner-text {
    color: #0b2239;
    font-size: 14px;
    font-weight: 200 !important;
    line-height: 24px;
  }
`;
