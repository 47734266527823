import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import SwitchComponent from "../../SwitchComponent";
import {
  GridContainer,
  StyledCard,
  StyledDiv,
  StyledDivider,
} from "../../styledComponent";
import { checkIsItemInOpportunityVariable } from "../../General/helper-general";

export default function TextBlockVariable({
  handleSaveNewValue,
  textBlock,
  setConfiguration,
  configuration,
  general,
}) {
  const configurationRef = useRef(configuration);
  const initializeItems = () => [
    {
      initialValue: textBlock.opportunityName === null,
      labelText: "Nom de l’opportunité",
      name: "opportunityName",
    },
    {
      initialValue: textBlock.offerName === null,
      labelText: "Nom de l’offre",
      name: "offerName",
    },
    {
      initialValue: textBlock.opportunityStartDate === null,
      labelText: "Date de début",
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_date",
        "mandatory",
        configurationRef.current
      ),
      name: "opportunityStartDate",
    },
    {
      initialValue: textBlock.opportunityEndDate === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_date",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Date de fin",
      name: "opportunityEndDate",
    },
    {
      initialValue: textBlock.opportunitySubmissionDate === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_limit_date",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Date de soumission",
      name: "opportunitySubmissionDate",
    },
    {
      initialValue: textBlock.opportunityValidityDate === null,
      labelText: "Date de validité",
      name: "opportunityValidityDate",
    },
    {
      initialValue: textBlock.opportunityType === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_response_type",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Type d’opportunité",
      name: "opportunityType",
    },
    {
      initialValue: textBlock.responseType === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_response_type",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Type de réponse",
      name: "responseType",
    },
    {
      initialValue: textBlock.customerDecisionDate === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_date",
        "complement",
        configurationRef.current
      ),
      labelText: "Date de décision client",
      name: "customerDecisionDate",
    },
    {
      initialValue: textBlock.opportunityCreatedDate === null,
      labelText: "Date de création de l’opportunité",
      name: "opportunityCreatedDate",
    },
    {
      initialValue: textBlock.chono === null,
      labelText: "N° chrono",
      name: "chono",
    },
    {
      initialValue: textBlock.description === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "opportunity_description",
        "complement",
        configurationRef.current
      ),
      labelText: "Description",
      name: "description",
    },
    {
      initialValue: textBlock.estimatedAmount === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "estimate_price",
        "complement",
        configurationRef.current
      ),
      labelText: "Montant estimé",
      name: "estimatedAmount",
    },
    {
      initialValue: textBlock.siteTypologies === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "type_of_site",
        "complement",
        configurationRef.current
      ),
      labelText: "Typologies de site",
      name: "siteTypologies",
    },
    {
      initialValue: general.prixVente && textBlock.sellingPrice === null,
      isDisabled: !general.prixVente,
      labelText: "Prix de vente HT",
      name: "sellingPrice",
    },
    {
      initialValue: general.prixVente && textBlock.sellingPriceTTC === null,
      isDisabled: !general.prixVente,
      labelText: "Prix de vente TTC",
      name: "sellingPriceTTC",
    },
    {
      initialValue: textBlock.successProbability === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "probability_success",
        "complement",
        configurationRef.current
      ),
      labelText: "Probabilité de succès",
      name: "successProbability",
    },
    {
      initialValue: textBlock.cancellationProbability === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "probability_cancelation",
        "complement",
        configurationRef.current
      ),
      labelText: "Probabilité d’abandon",
      name: "cancellationProbability",
    },
    {
      initialValue: textBlock.providers === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provider_in_place",
        "complement",
        configurationRef.current
      ),
      labelText: "Prestataire(s) en place",
      name: "providers",
    },

    {
      initialValue: textBlock.frameworkAgreement === null,
      isDisabled: !general.accordCadre,
      labelText: "Nom de l’accord cadre",
      name: "frameworkAgreement",
      info: "Disponible uniquement si l’option Accord cadre est activée",
    },
  ];

  const [itemsFirstCol, setItemsFirstCol] = useState(initializeItems());

  const initializeCompanyContact = () => [
    {
      initialValue: textBlock.general_client_contacts_name === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "company_contact",
        "complement",
        configurationRef.current
      ),
      labelText: "Prénom et Nom",
      name: "general_client_contacts_name",
    },
    {
      initialValue: textBlock.general_client_contacts_phone === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "company_contact",
        "complement",
        configurationRef.current
      ),
      labelText: "N° de téléphone",
      name: "general_client_contacts_phone",
    },
    {
      initialValue: textBlock.general_client_contacts_address === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "company_contact",
        "complement",
        configurationRef.current
      ),
      labelText: "Adresse",
      name: "general_client_contacts_address",
    },
    {
      initialValue: textBlock.general_client_contacts_mail === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "company_contact",
        "complement",
        configurationRef.current
      ),
      labelText: "Email",
      name: "general_client_contacts_mail",
    },
  ];
  const [itemsCompanyContacts, setItemsCompanyContacts] = useState(
    initializeCompanyContact()
  );

  const initializeDecisionContact = () => [
    {
      initialValue: textBlock.decision_client_contacts_name === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Prénom et Nom",
      name: "decision_client_contacts_name",
    },
    {
      initialValue: textBlock.decision_client_contacts_phone === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "N° de téléphone",
      name: "decision_client_contacts_phone",
    },
    {
      initialValue: textBlock.decision_client_contacts_address === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Adresse",
      name: "decision_client_contacts_address",
    },
    {
      initialValue: textBlock.decision_client_contacts_mail === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Email",
      name: "decision_client_contacts_mail",
    },
  ];

  const [itemsDecisionContacts, setItemsDecisionContacts] = useState(
    initializeDecisionContact()
  );

  const initializeProvidedContact = () => [
    {
      initialValue: textBlock.provided_client_contacts_name === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Prénom et Nom",
      name: "provided_client_contacts_name",
    },
    {
      initialValue: textBlock.provided_client_contacts_phone === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "N° de téléphone",
      name: "provided_client_contacts_phone",
    },
    {
      initialValue: textBlock.provided_client_contacts_address === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Adresse",
      name: "provided_client_contacts_address",
    },
    {
      initialValue: textBlock.provided_client_contacts_mail === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configurationRef.current
      ),
      labelText: "Email",
      name: "provided_client_contacts_mail",
    },
  ];

  const [itemsProvidedContacts, setItemsProvidedContacts] = useState(
    initializeProvidedContact()
  );

  const initializeBilledContact = () => [
    {
      initialValue: textBlock.billed_client_contacts_name === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Prénom et Nom",
      name: "billed_client_contacts_name",
    },
    {
      initialValue: textBlock.billed_client_contacts_phone === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configurationRef.current
      ),
      labelText: "N° de téléphone",
      name: "billed_client_contacts_phone",
    },
    {
      initialValue: textBlock.billed_client_contacts_address === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Adresse",
      name: "billed_client_contacts_address",
    },
    {
      initialValue: textBlock.billed_client_contacts_mail === null,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configurationRef.current
      ),
      labelText: "Email",
      name: "billed_client_contacts_mail",
    },
  ];

  const [itemsBilledContacts, setItemsBilledContacts] = useState(
    initializeBilledContact()
  );

  useEffect(() => {
    configurationRef.current = configuration;
  }, [configuration]);

  useEffect(() => {
    const updatedConfiguration = {
      ...configurationRef.current,
      api: {
        ...configurationRef.current.api,
        opportunity_variable: {
          ...configurationRef.current?.api?.opportunity_variable,
          NAME: textBlock.opportunityName,
          OFFER_NAME: textBlock.offerName,
          QUOTATION_START_DATE: textBlock.opportunityStartDate,
          QUOTATION_END_DATE: textBlock.opportunityEndDate,
          SUBMISSION_DATE: textBlock.opportunitySubmissionDate,
          VALIDITY_DATE: textBlock.opportunityValidityDate,
          OPPORTUNITY_TYPE: textBlock.opportunityType,
          RESPONSE_TYPE: textBlock.responseType,
          CUSTOMER_DECISION_DATE: textBlock.customerDecisionDate,
          OPPORTUNITY_CREATED_DATE: textBlock.opportunityCreatedDate,
          CHRONO: textBlock.chono,
          DESCRIPTION: textBlock.description,
          ESTIMATED_AMOUNT: textBlock.estimatedAmount,
          SITE_TYPOLOGIES: textBlock.siteTypologies,
          SELLING_PRICE: textBlock.sellingPrice,
          SELLING_PRICE_VAT: textBlock.sellingPriceTTC,
          SUCCESS_PROBABILITY: textBlock.successProbability,
          CANCELLATION_PROBABILITY: textBlock.cancellationProbability,
          PROVIDERS: textBlock.providers,
          FRAMEWORK_AGREEMENT: textBlock.frameworkAgreement,
          COMPANY_CONTACT_NAME: textBlock.general_client_contacts_name,
          COMPANY_CONTACT_PHONE: textBlock.general_client_contacts_phone,
          COMPANY_CONTACT_ADDRESS: textBlock.general_client_contacts_address,
          COMPANY_CONTACT_MAIL: textBlock.general_client_contacts_mail,
          PROVIDED_CONTACT_NAME: textBlock.provided_client_contacts_name,
          PROVIDED_CONTACT_PHONE: textBlock.provided_client_contacts_phone,
          PROVIDED_CONTACT_ADDRESS: textBlock.provided_client_contacts_address,
          PROVIDED_CONTACT_MAIL: textBlock.provided_client_contacts_mail,
          DECISION_CONTACT_NAME: textBlock.decision_client_contacts_name,
          DECISION_CONTACT_PHONE: textBlock.decision_client_contacts_phone,
          DECISION_CONTACT_ADDRESS: textBlock.decision_client_contacts_address,
          DECISION_CONTACT_MAIL: textBlock.decision_client_contacts_mail,
          CLIENT_CONTACT_NAME: textBlock.billed_client_contacts_name,
          CLIENT_CONTACT_PHONE: textBlock.billed_client_contacts_phone,
          CLIENT_CONTACT_ADDRESS: textBlock.billed_client_contacts_address,
          CLIENT_CONTACT_MAIL: textBlock.billed_client_contacts_mail,
        },
      },
    };
    setConfiguration(updatedConfiguration);

    setItemsFirstCol(initializeItems());
    setItemsCompanyContacts(initializeCompanyContact());
    setItemsDecisionContacts(initializeDecisionContact());
    setItemsProvidedContacts(initializeProvidedContact());
    setItemsBilledContacts(initializeBilledContact());
  }, [textBlock]);

  const handleSwitchChange = (value, name) => {
    handleSaveNewValue(value, name, "textBlock");
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <StyledDivider
        orientation="left"
        style={{
          height: "24px",
          width: "100%",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      >
        Texte
      </StyledDivider>
      <StyledDiv>
        <DivText
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,48%)",
            backgroundColor: "#F5F6F8",
            borderRadius: 10,
            width: "100%",
          }}
        >
          {itemsFirstCol.map((item) => (
            <SwitchComponent
              key={item.name}
              initialValue={item.initialValue}
              labelText={item.labelText}
              handleChange={(value) => handleSwitchChange(value, item.name)}
              name={item.name}
              info={item.info}
              isDisabled={item?.isDisabled}
            />
          ))}
        </DivText>
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Contacts client facturé"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {itemsBilledContacts.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value) => handleSwitchChange(value, item.name)}
                name={item.name}
                info={item.info}
                isDisabled={item?.isDisabled}
              />
            ))}
          </GridContainer>
        </StyledCard>

        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Contacts client presté"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {itemsProvidedContacts.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value) => handleSwitchChange(value, item.name)}
                name={item.name}
                info={item.info}
                isDisabled={item?.isDisabled}
              />
            ))}
          </GridContainer>
        </StyledCard>

        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Contacts client décisionnaire"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {itemsDecisionContacts.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value) => handleSwitchChange(value, item.name)}
                name={item.name}
                info={item.info}
                isDisabled={item?.isDisabled}
              />
            ))}
          </GridContainer>
        </StyledCard>

        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Contacts généraux"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {itemsCompanyContacts.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value) => handleSwitchChange(value, item.name)}
                name={item.name}
                info={item.info}
                isDisabled={item?.isDisabled}
              />
            ))}
          </GridContainer>
        </StyledCard>
      </StyledDiv>
    </div>
  );
}
const DivText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
`;
